import React, { ComponentProps, FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useUserContext } from 'src/context/user';
import { Header } from 'src/components';
import { ClassicLinkButton } from 'src/componentsClassic';
import { ROUTES } from '../../constants/routes.const';
import * as S from './styles';
import { ProfileDropdown as ProfileDropdownComponent } from './ProfileDropdown';

const msgs = defineMessages({
  premium: {
    id: 'header_link_go_pro',
  },
  upgrade: {
    id: 'header_button_upgrade',
  },
});

export type AuthenticatedHeaderProps = ComponentProps<typeof Header>;

export const AuthenticatedHeader: FC<AuthenticatedHeaderProps> = props => {
  const intl = useIntl();
  const { user } = useUserContext();
  const isNonPremiumUser = user && !user.isPro;

  return (
    <Header {...props} currentUrl={props.currentUrl}>
      {props.children}
      {isNonPremiumUser && (
        <S.ButtonWrapper $minimalHeader={props.minimalHeader}>
          {props.linkConfig === 'experience2024' ? (
            <S.UpgradeButton href={ROUTES.payment.plans} icon="star">
              {intl.formatMessage(msgs.upgrade)}
            </S.UpgradeButton>
          ) : (
            <ClassicLinkButton buttonSize="small" href={ROUTES.payment.plans}>
              {intl.formatMessage(msgs.premium)}
            </ClassicLinkButton>
          )}
        </S.ButtonWrapper>
      )}
    </Header>
  );
};

export const ProfileDropdown = ProfileDropdownComponent;
