import React, { ComponentProps, FC, useMemo } from 'react';
import { Stack } from 'src/components';
import HeaderDropdownIcon from 'src/components/HeaderDropdownIcon';
import { defineMessages, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useUserContext } from 'src/context/user';
import * as S from '../styles';
import { getDropdownSections } from './ProfileDropdownHelpers/getDropdownSections';

const msgs = defineMessages({
  profileTooltip: { id: 'dashboard_tooltip_profile_dropdown' },
  scenariosProfileTooltip: {
    id: 'scenario_dashboard_tooltip_profile_dropdown',
    defaultMessage: '{yourAccount}: {email}',
  },
  yourAccount: {
    id: 'scenario_dashboard_tooltip_profile_dropdown_your_account',
    defaultMessage: 'Your account',
  },
});

export const ProfileDropdown: FC<{
  oldDashboard?: boolean;
  isSupportedLanguagePairImmerse?: boolean;
  isSupportedLanguagePairCommunicate?: boolean;
  hasBeenInClassicAndBeta?: boolean;
}> = ({
  oldDashboard = false,
  isSupportedLanguagePairImmerse,
  isSupportedLanguagePairCommunicate,
  hasBeenInClassicAndBeta,
}) => {
  const user = useUserContext();
  const router = useRouter();
  const intl = useIntl();

  const items = getDropdownSections({
    oldDashboard,
    intl,
    user,
  });

  // @ts-ignore: incompatible types due to menu item display condition
  const profileSections: ComponentProps<typeof HeaderDropdownIcon>['dropdownSections'] = useMemo(
    () => items,
    // eslint-disable-next-line no-warning-comments
    // TODO: check does it need items
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      intl,
      router,
      user.user?.statistics.points,
      user.user?.statistics.wordsLearnt,
      user.user?.username,
      isSupportedLanguagePairImmerse,
      isSupportedLanguagePairCommunicate,
      hasBeenInClassicAndBeta,
    ],
  );

  const profileIconText = intl.formatMessage(msgs.yourAccount);

  return (
    <Stack $direction="horizontal" $spacing="s8">
      {oldDashboard ? (
        <HeaderDropdownIcon
          imageSrc={user.user?.avatar.small}
          dropdownSections={profileSections}
          tooltipText={intl.formatMessage(msgs.profileTooltip)}
          ariaText={profileIconText}
        />
      ) : (
        <HeaderDropdownIcon
          iconName="profileIcon"
          imageSrc={user.user?.avatar.small}
          dropdownSections={profileSections}
          tooltipText={intl.formatMessage(msgs.scenariosProfileTooltip, {
            yourAccount: <strong>{profileIconText}</strong>,
            email: <S.NoWrap>{user.user?.email}</S.NoWrap>,
          })}
          isPremium={user.user?.isPro}
          ariaText={profileIconText}
        />
      )}
    </Stack>
  );
};
