export enum ActivitySource {
  CONVERSATIONS_TAB = 'conversations_tab',
  EOC = 'core_loop_end_of_conversation',
  EODW = 'core_loop_end_of_dw_session',
  EOL = 'core_loop_end_of_learn_session',
  EOR = 'core_loop_end_of_review_session',
  EOV = 'core_loop_end_of_video',
  HOME_TAB = 'core_loop_home_tab',
  MEDIA_PAGE = 'media_page',
  ONBOARDING = 'onboarding',
  REVIEW_TAB = 'review_tab',
  SCENARIOS_TAB = 'scenarios_tab',
  VIDEOS_TAB = 'videos_tab',
}

export enum PlatformPage {
  BUDDIES = 'buddies',
  BUDDY = 'buddy',
  CONVERSATIONS = 'conversations',
  HOME = 'home',
  LANGUAGES = 'languages',
  LEARNING = 'learning settings',
  MEDIA = 'media',
  PROFILE = 'profile settings',
  REVIEW = 'review',
  PROGRESS = 'progress',
  SCENARIOS = 'scenarios',
  USER_DICTIONARY = 'user-dictionary',
  VIDEOS = 'videos',
}
