import styled, { css } from 'styled-components';
import { ClassicLinkButton } from 'src/componentsClassic';

export const ButtonWrapper = styled.div<{ $minimalHeader?: boolean }>(
  ({ theme, $minimalHeader }) => css`
    display: none;

    @media (min-width: ${$minimalHeader ? theme.sz.xl : theme.sz.sm}) {
      display: flex;
      justify-content: flex-end;
    }
  `,
);

export const NoWrap = styled.span(
  () => css`
    white-space: nowrap;
  `,
);

export const UpgradeButton = styled(ClassicLinkButton)(
  ({ theme }) => css`
    white-space: nowrap;
    height: ${theme.sz.s8};
    font-size: ${theme.DEPRECATED_ty.small};
    color: ${theme.co.secondary0};
    background: linear-gradient(180deg, ${theme.co.primary20} 43.92%, ${theme.co.warning50} 100%);
    box-shadow: 0 ${theme.sz.s1} 0 ${theme.co.primary0};
    border-radius: ${theme.sz.s10};

    &:hover,
    &:focus {
      background: ${theme.co.primary40} !important; // override data-styled injection
      color: ${theme.co.secondary0};
    }

    &:active {
      background: ${theme.co.primary0} !important; // override data-styled injection
      color: ${theme.co.secondary0};
      box-shadow: none;
    }

    & span {
      margin: 0;
    }

    & svg {
      width: ${theme.sz.s3};
      height: ${theme.sz.s3};
    }
  `,
);
