import React, { FC } from 'react';
import { SvgHelperWrapper } from 'src/components';
import Badge from './badge.svg';
import * as S from './styles';

interface Props {
  level: number;
  size?: S.Size;
  children?: never;
}

export const LevelBadge: FC<Props> = ({ level, size = 'm' }) => {
  return (
    <S.Wrapper $size={size} data-testid="level-badge-wrapper">
      <S.BadgeContainer>
        <SvgHelperWrapper Svg={Badge} />
      </S.BadgeContainer>
      <S.Level>{level}</S.Level>
    </S.Wrapper>
  );
};
